/* eslint-disable import/order */
import 'modern-normalize'
import '../styles/normalize'

import * as React from 'react'

// import { Link } from 'gatsby'
// import Page from '../components/Page'
// eslint-disable-next-line import/order
import { faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IndexLayout from '../layouts'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

// import Container from '../components/Container'

const Home = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('max.png');
  background-color: black;
`

const IndexPage = () => {
  const [isNarrow, setIsNarrow] = React.useState(false)
  React.useEffect(() => {
    const handleResize = () => setIsNarrow(window.innerWidth * 1.2 < window.innerHeight)
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  const fontMultiplier = isNarrow ? 2 : 1
  const Name = styled.h1`
    color: white;
    font-size: ${6 * fontMultiplier}vw;
    text-shadow: 0 0 2em black, 0 0 1em black;
    font-family: 'Rock Salt';
    text-align: center;
  `
  const Subtitle = styled.h3`
    color: white !important;
    & a {
      color: white;
      text-decoration: underline;
    }
    text-shadow: 0 0 2em black, 0 0 1em black;
    font-size: ${3 * fontMultiplier}vw;
    line-height: ${3 * fontMultiplier}vw;
    font-family: Raleway;
    text-align: center;
  `

  const SocialIcon = styled.a`
    color: white;
    display: ${isNarrow ? 'block' : 'inline-block'};
    margin: ${isNarrow ? '1vw' : '0.5em'};
    font-size: ${isNarrow ? '10vw' : '3vw'};
  `

  const ShiftBlock = styled.div`
    display: inline-block;
    margin-right: ${isNarrow ? undefined : '5vw'};
    padding-right: ${isNarrow ? '0.5em' : undefined};
    margin: ${isNarrow ? 'auto' : undefined};
    margin-top: ${isNarrow ? '-15vh' : undefined};
  `
  const MainBlock = styled.div`
    display: flex;
    justify-content: end;
  `
  return (
    <IndexLayout suppressHeader>
      <Home>
        <MainBlock>
          <ShiftBlock>
            <Name>Max Cantor</Name>
            <Subtitle>
              {/* <Link to="/blog/2020-06-25-a-moment-for-my-birthday">a birthday wish</Link> • <Link to="/blog">all posts</Link> */}
              <Link to="/readme">my readme</Link> • <Link to="/blog">all posts</Link>
            </Subtitle>
            <Subtitle>fitness • startups • tech</Subtitle>
            <Subtitle>
              <SocialIcon style={isNarrow ? { marginTop: '15vw' } : {}} target="_blank" href="https://twitter.com/maxcan">
                <FontAwesomeIcon icon={faTwitter} />
              </SocialIcon>
              &nbsp;
              <SocialIcon target="_blank" href="https://instagram.com/maxcan">
                <FontAwesomeIcon icon={faInstagram} />
              </SocialIcon>
              &nbsp;
              <SocialIcon target="_blank" href="https://www.linkedin.com/in/mxcan/">
                <FontAwesomeIcon icon={faLinkedin} />
              </SocialIcon>
            </Subtitle>
          </ShiftBlock>
        </MainBlock>
      </Home>
    </IndexLayout>
  )
}

export default IndexPage
